define([], () => {
  const DEFAULT_SHOW_PASSWORD_TARGET = 'input[data-show-password-target]';
  const _children = {
    checkbox: '.showPasswordToggleComponent_checkbox',
    switch: '.showPasswordToggleComponent_switch',
  };

  const showPasswordToggleComponent = () => {
    const component = {};

    component.init = (element) => {
      component.element = element;
      component.children = {};

      for (const [key, selector] of Object.entries(_children)) {
        component.children[key] = document.querySelector(selector);
      }

      component.targetsSelector = DEFAULT_SHOW_PASSWORD_TARGET;

      component.addListeners();
      return component;
    };

    component.addListeners = () => {
      component.children.checkbox.addEventListener('change', component.onChange);
    };

    component.onChange = () => {
      if (component.children.checkbox.checked) {
        component.switchOn();
      } else {
        component.switchOff();
      }
    };

    component.switchOn = () => {
      const targets = document.querySelectorAll(component.targetsSelector);
      for (const target of targets) {
        target.type = 'text';
      }
    };

    component.switchOff = () => {
      const targets = document.querySelectorAll(component.targetsSelector);
      for (const target of targets) {
        target.type = 'password';
      }
    };

    return component;
  };

  return showPasswordToggleComponent;
});
